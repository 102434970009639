<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    en: {},
    pt: {
      Withdrawal: "Sacar",
      "You do not have a registered bitcoin wallet address.":
        "Você ainda não cadastrou uma carteira bitcoin",
      "Click here to register": "Clique aqui para cadastrar",
      "Target bank": "Banco de destino",
      "Choose your wallet": "Escolha sua carteira",
      "Wallet is required.": "A carteira é obrigatório.",
      "Balance available": "Saldo disponível",
      "Withdrawal to": "Saque para",
      "Withdrawal amount": "Valor do saque",
      "Amount is required.": "O valor é obrigatório.",
      "Withdrawal fee": "Taxa do saque",
      "Security password": "Senha de segurança",
      Request: "Solicitar",
      "The wallet is invalid.": "A carteira é inválida.",
      "It's just released a withdrawal per day.":
        "É permitido apenas um saque por dia.",
      "Enter a withdrawal amount.": "Digite o valor do saque.",
      "The minimum withdrawal amount is R$ 25,00":
        "O valor mínimo para saque é de R$ 25,00",
      "You do not have enough balance to withdraw this wallet.":
        "Você não possui saldo suficiente para realizar o saque.",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",
      "Your withdrawal has been successfully requested.":
        "Seu saque foi solicitado com sucesso.",
      "Withdrawal is only allowed from the %from%th to the %to%th of each month.":
        "O saque está liberado somente do dia %from% a %to% de cada mês.",
    },
    es: {
      Withdrawal: "Retirada",
      "You do not have a registered bitcoin wallet address.":
        "Usted no tiene una cartera bitcoin registrada.",
      "Click here to register": "Pulse aquí para registrarse",
      "Target bank": "Banco de destino",
      "Choose your wallet": "Elija su cartera",
      "Balance available": "Saldo disponible",
      "Withdrawal to": "Retirada para",
      "Withdrawal amount": "Valor da retirada",
      "Amount is required.": "O valor é required.",
      "Withdrawal fee": "Taxa da retirada",
      "Security password": "Contraseña de seguridad",
      Request: "Solicitar",
      "The wallet is invalid.": "A carteira é inválida.",
      "It's just released a withdrawal per day.":
        "É permitido apenas um saque por dia.",
      "Enter a withdrawal amount.": "Digite o valor do saque.",
      "The minimum withdrawal amount is R$ 25,00":
        "O valor mínimo para saque é de R$ 25,00",
      "You do not have enough balance to withdraw this wallet.":
        "Você não possui saldo suficiente para realizar o saque.",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",
      "Your withdrawal has been successfully requested.":
        "Su retirada se ha solicitado con éxito.",
      "Withdrawal is only allowed from the %from%th to the %to%th of each month.":
        "O saque está liberado somente do dia %from% a %to% de cada mês.",
    },
  },
  components: { Layout, Password },
  data() {
    return {
      withdrawal: false,
      loading: false,
      success: false,

      config: {
        types: [],
      },

      bank: {
        status: false,
        nome: "",
        conta: "",
        agencia: "",
      },

      pix: {
        status: false,
        type: "",
        key: "",
      },

      bitcoin: {
        status: false,
        address: "",
      },

      wallet: "network",
      type: "",
      balance: "R$ -,--",
      value: "",
      fee: "",
      password: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
      alert: {
        type: "",
        message: "",
        params: [],
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    type: { required },
    value: { required },
    password: { required },
  },
  methods: {
    getWithdrawal() {
      api.get("wallet/withdrawal/config").then((response) => {
        if (response.data.status == "success") {
          this.withdrawal = true;
          this.config.types = response.data.types;
          this.type = "bank";
          this.fee = response.data.fee;
        }
      });
    },
    getBank() {
      api.get("user/bank").then((response) => {
        if (response.data.status == "success") {
          this.bank.status = true;
          this.bank.name = response.data.bank.name;
          this.bank.agency = response.data.agency;
          this.bank.account = response.data.account;
        } else {
          this.bank.status = false;
        }
      });
    },
    getPix() {
      api.get("user/pix").then((response) => {
        if (response.data.status == "success") {
          this.pix.status = true;
          this.pix.type = response.data.type;
          this.pix.key = response.data.key;
        } else {
          this.pix.status = false;
        }
      });
    },
    getBitcoin() {
      api.get("wallet/bitcoin").then((response) => {
        if (response.data.status == "success") {
          this.bitcoin.status = true;
          this.bitcoin.address = response.data.default;
        } else {
          this.bitcoin.status = false;
        }
      });
    },
    getBalance(wallet) {
      this.balance = "R$ -,--";
      api.get("wallet/balance/" + wallet).then((response) => {
        if (response.data.status == "success") {
          this.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    withdrawalSubmit() {
      this.loading = true;
      this.$v.$touch();

      if (this.value) {
        api
          .post("wallet/withdrawal", {
            wallet: this.wallet,
            type: this.type,
            value: this.value,
            password: this.password,
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.success = true;
              this.value = "R$ 0,00";
              this.password = "";
              this.$v.$reset();
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
              if (response.data.params) {
                this.alert.params = response.data.params;
              } else {
                this.alert.params = [];
              }

              this.password = "";
              this.$v.$reset();
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getBank();
    this.getPix();
    this.getBitcoin();
    this.getWithdrawal();
    this.getBalance("network");
  },
  watch: {
    "alert.params": function (params) {
      if (params) {
        this.alert.message = this.t(this.alert.message);
        Object.keys(params).forEach((key) => {
          this.alert.message = this.alert.message.replace(
            `%${key}%`,
            params[key]
          );
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <div>
            <h6 class="m-0 text-uppercase font-size-12">Carteira de Rede</h6>
            <h4 class="mb-0 font-size-18">{{ t("Withdrawal") }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="!withdrawal"></div>
            <div v-if="success" class="text-center-sm">
              {{ t("Your withdrawal has been successfully requested.") }}
            </div>
            <b-form v-else class="p-3" @submit.prevent="withdrawalSubmit">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form-group
                id="type"
                :label="t('Método de Saque')"
                label-for="type"
              >
                <select v-model="type" class="custom-select mb-2">
                  <option value="">–</option>
                  <option
                    v-for="(value, key) in config.types"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
                <div v-if="$v.type.$error" class="invalid-feedback">
                  <span v-if="!$v.type.required">{{
                    t("O método de saque é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <template v-if="type == 'bank'">
                <b-form-group
                  v-if="bank.status"
                  id="bank"
                  :label="t('Target bank')"
                  label-for="bank"
                >
                  <div class="border border-secondary rounded p-2 mb-2">
                    {{ bank.name }}
                  </div>
                  <div class="bg-soft-primary rounded p-3">
                    <span class="mr-4"
                      >Agência: {{ bank.agency.number }}-{{
                        bank.agency.digit
                      }}</span
                    >
                    <span
                      >Conta: {{ bank.account.number }}-{{
                        bank.account.digit
                      }}</span
                    >
                  </div>
                </b-form-group>
                <b-form-group
                  v-else
                  id="bank"
                  :label="t('Target bank')"
                  label-for="bank"
                >
                  <div
                    class="d-flex align-items-center bg-soft-primary rounded p-3"
                  >
                    <span class="flex-fill"
                      >Você ainda não tem um conta bancária cadastrada.</span
                    >
                    <router-link
                      :to="'/account/bank'"
                      class="btn btn-link m-0 p-0 pull-right"
                    >
                      <i
                        class="bx bx-plus-circle font-size-24 align-middle"
                      ></i>
                    </router-link>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="type == 'pix'">
                <b-form-group
                  v-if="pix.status"
                  id="pix"
                  :label="t('PIX')"
                  label-for="pix"
                >
                  <div class="border border-secondary rounded p-2 mb-2">
                    <strong>
                    <template v-if="pix.type == 'cpf'">CPF</template>
                    <template v-if="pix.type == 'cnpj'">CNPJ</template>
                    <template v-if="pix.type == 'cellphone'">Telefone</template>
                    <template v-if="pix.type == 'email'">E-mail</template>
                    <template v-if="pix.type == 'random'">Chave Aleatória</template>
                    </strong
                    ><br />
                    {{ pix.key }}
                  </div>
                </b-form-group>
                <b-form-group v-else id="pix" :label="t('PIX')" label-for="pix">
                  <div
                    class="d-flex align-items-center bg-soft-primary rounded p-3"
                  >
                    <span class="flex-fill"
                      >Você ainda não tem um pix cadastrado.</span
                    >
                    <router-link
                      :to="'/account/pix'"
                      class="btn btn-link m-0 p-0 pull-right"
                    >
                      <i
                        class="bx bx-plus-circle font-size-24 align-middle"
                      ></i>
                    </router-link>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="type == 'bitcoin'">
                <b-form-group
                  v-if="bitcoin.status"
                  id="bitcoin"
                  :label="t('Carteira Bitcoin')"
                  label-for="bitcoin"
                >
                  <div class="border border-secondary rounded p-2 mb-2">
                    <strong>{{ bitcoin.address }}</strong>
                  </div>
                </b-form-group>
                <b-form-group v-else id="bitcoin" :label="t('Carteira Bitcoin')" label-for="bitcoin">
                  <div
                    class="d-flex align-items-center bg-soft-primary rounded p-3"
                  >
                    <span class="flex-fill"
                      >Você ainda não tem uma carteira bitcoin cadastrada.</span
                    >
                    <router-link
                      :to="'/account/bitcoin'"
                      class="btn btn-link m-0 p-0 pull-right"
                    >
                      <i
                        class="bx bx-plus-circle font-size-24 align-middle"
                      ></i>
                    </router-link>
                  </div>
                </b-form-group>
              </template>

              <hr />
              <b-form-group
                id="balance"
                :label="t('Saldo disponível')"
                label-for="balance"
              >
                <b-form-input
                  id="balance"
                  v-model="balance"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="value"
                :label="t('Withdrawal amount')"
                label-for="value"
              >
                <b-input-group>
                  <b-form-input
                    id="value"
                    v-model="value"
                    v-money="money"
                    type="text"
                    inputmode="decimal"
                    :class="{ 'is-invalid': $v.value.$error }"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="default" v-on:click="value = balance"
                      >Usar Saldo</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{
                    t("Amount is required.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="password"
                :label="t('Security password')"
                label-for="password"
              >
                <Password v-model="password" :v="$v.password"></Password>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="
                    loading == true ||
                    this.value === 'R$ 0,00' ||
                    !this.password
                  "
                  type="submit"
                  variant="default"
                >
                  {{ t("Request") }}
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>